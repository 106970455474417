import ObjectStorage from "./MainProducts/ObjectStorage.jsx";

const ObjStoragePurchase = () => {
  return (
    <div className="relative w-full h-svh flex items-center">
      <div className="w-full relative max-w-[60em] mx-auto">
        <ObjectStorage />
      </div>
    </div>
  );
};
export default ObjStoragePurchase;
