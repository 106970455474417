import ArrayStorage from "./MainProducts/ArrayStorage.jsx";

const ArrStoragePurchase = () => {
  return (
    <div className="relative w-full h-svh flex items-center">
      <div className="w-full relative max-w-[60em] mx-auto">
        <ArrayStorage />
      </div>
    </div>
  );
};
export default ArrStoragePurchase;
