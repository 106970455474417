import React, { useState, useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import "./waitPage.scss";
import services from "../../Services/services";
import { orderStatus } from "../../shared/enums/orderStatus";
const WaitPage = () => {
  // Define an array of messages
  const messages = [
    "We're Checking your payment. This may take a few moments, so sit tight!",
    "Your Payment is not successful. Please try again.",
    "Your Payment is successful.Our servers are hard at work creating your virtual environment. Hang in there!",
    "We expect your virtual machine to be ready shortly. Stay tuned!",
    "Thank you, you are done.Check your email to find you credentials.",
  ];

  const [fetchError, setFetchError] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);

  const [showCheckMarks, setShowCheckMarks] = useState(false);

  useEffect(() => {
    const id = getIdFromUrl();
    const timer = setTimeout(async () => {
      // setMessageIndex((prevIndex) =>
      //   prevIndex < messages.length - 1 ? prevIndex + 1 : prevIndex
      // );

      // setTimeout(() => {
      //   setShowCheckMarks((prev) =>
      //     prev.map((value, index) => (index === messageIndex ? true : value))
      //   );
      //  }, 5000);
      const order = await getOrderById(id);

      if (order?.status === orderStatus.PAYMENT_FAILED) {
        setShowCheckMarks(true);
        setMessageIndex(1);
      } else if (order?.status === orderStatus.PAYMENT_SUCCESS) {
        setShowCheckMarks(false);
        setMessageIndex(2);
      } else if (order?.status === orderStatus.ORDER_CREATED) {
        setShowCheckMarks(true);
        setMessageIndex(4);
      }
    }, 8000);
    return () => clearTimeout(timer);
  }, [messageIndex, messages.length]);

  // useEffect(() => {

  // });

  const getIdFromUrl = () => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const id = searchParams.get("orderId");
    return id;
  };

  const getOrderById = async (orderId) => {
    try {
      const response = await services.getOrderById(orderId);
      return response;
    } catch (error) {
      setFetchError(true);
    } finally {
    }
  };
  return (
    <div className="h-screen font-DMSans flex items-center justify-center p-4 bg-gray-50">
      <div className="relative flex flex-col md:flex-row items-center gap-8 justify-center -mt-[5em]">
        <div className="waitLoader mr-4"></div>
        <ul className="flex flex-col gap-4 items-start">
          {messages.map((message, index) => (
            <li
              className="flex gap-3 items-center"
              key={index}
              style={{ display: index <= messageIndex ? "flex" : "none" }}
            >
              {showCheckMarks ? (
                <CheckCircleIcon className="text-green-600" />
              ) : (
                <div>
                  <CircularProgress
                    className="text-green-600"
                    size={24}
                    thickness={4}
                  />
                </div>
              )}
              <span className="xl:text-xl text-gray-600">{message}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WaitPage;
